import './App.css';
import React from 'react';
import "@fontsource/quicksand"; // Defaults to weight 400
import "@fontsource/quicksand/400.css";
import {BrowserRouter, Route, Router, Routes} from "react-router-dom"; // Specify weight
import Base from "./base/Base"
import Increase from "./increase/Increase"

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Base />} />
                <Route path="/v2" element={<Increase />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

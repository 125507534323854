import logo from './logo.png';
import './Base.css';
import {Autocomplete, Box, Button, Checkbox, FormControl, Slider, Stack, TextField, Typography} from "@mui/material";
import React, {useState, useEffect} from 'react';
import axios from "axios";
import "@fontsource/quicksand"; // Defaults to weight 400
import "@fontsource/quicksand/400.css"; // Specify weight
function Base() {
    const minAmount=3000000;
    const maxAmount=20000000;
    const [data] = useState({
        fullName: "",
        phone: "",
        identifier: ""
    });
    // eslint-disable-next-line no-undef
    const [valueAmount, setValueAmount] = React.useState(5000000);
    const [consent, setConsent] = React.useState(false);
    function getAmountText(value: number){
        return   new Intl.NumberFormat().format(value) + ` VNĐ`
    }

    function isVietnamesePhoneNumberValid(number) {
        return /(((\+|)84)|0)+([0-9]{9})\b/.test(number);
    }

    const [errorMessage, setErrorMessage] = React.useState({});
    const [fieldError, setFieldError] = React.useState({});
    function validateOnBlurFullName() {
        setFieldError({})
        setErrorMessage({});
        setValidateMessage("");
        if (data.fullName.length < 2) {
            setFieldError({fullName: true});
            setErrorMessage({fullName: "Sai định dạng họ tên"});
            return false;
        }
        return true
    }
    function validateOnBlurPhone() {
        setFieldError({})
        setErrorMessage({});
        setValidateMessage("");
        if (!isVietnamesePhoneNumberValid(data.phone)) {
            setFieldError({phone: true})
            setErrorMessage({phone: "Sai định dạng Số điện thoại"})
            return false;
        }
        return true
    }
    function validateOnBlurIdentifier() {
        setFieldError({})
        setErrorMessage({});
        setValidateMessage("");
        if (data.identifier.length < 9 ) {
            setFieldError({identifier:true})
            setErrorMessage( {identifier:"Sai định dạng căn cước công dân"})
            return;
        }
        return true

    }

    const [validateMessage, setValidateMessage] = React.useState("");
    function validateOnSubmit() {
        setValidateMessage("");
        if (!consent) {
            setValidateMessage("Yêu cầu chưa được gửi đi do \"Chưa tích vào Ô đồng ý Điều khoản, điều kiện về bảo vệ dữ liệu cá nhân\"");
            return false;
        }
        return true;
    }
    const [isSubmit, setIsSubmit] = React.useState(false);
    function submitData() {
        renderButton();
        if(validateOnBlurFullName() || validateOnBlurPhone() || validateOnBlurIdentifier()){
            if(validateOnSubmit()){
                setIsSubmit(true);
                callReceiver();
            }
        }
    }


    function callReceiver(){
        const config = {
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJwYXNzd29yZFwiOlwiXCIsXCJ1c2VybmFtZVwiOlwidG5leF9sYW5kaW5nX3BhZ2VcIixcImF1dGhvcml0aWVzXCI6W10sXCJhY2NvdW50Tm9uRXhwaXJlZFwiOnRydWUsXCJhY2NvdW50Tm9uTG9ja2VkXCI6dHJ1ZSxcImNyZWRlbnRpYWxzTm9uRXhwaXJlZFwiOnRydWUsXCJlbmFibGVkXCI6dHJ1ZX0iLCJleHAiOjE3ODc0NDcxNDIsImlhdCI6MTcwMTA0NzE0Mn0.tG1l7y6qP0Kqx_7SohTAMzUg9kLLnUlCo7l5_wwiqOIluB_SjPxuAddlmjPc-fZmLSx18wwkcd2ONcRs2DtE8A',
                'Content-Type': 'application/json',
            },
        };


        axios.post( 'https://receiver.dagoras.io/receiver', {
            "fullName":data.fullName,
            "phone":data.phone,
            "identityNumber":data.identifier,
            "loanAmount":valueAmount,
            "link": window.location.href,
        }, config)
            .then(response => {
                if(window.location.href.indexOf("ViettelPost")>-1) {
                    window.location.href = "https://dagoras.tnexfinance.com.vn/thankyou";
                }else{
                    window.location.href = "https://tnexcampaign.onelink.me/93RY/pjo9ho9a";
                }
            })
            .catch(function () {
                saveToBackup();
        });
    }

    function saveToBackup(){
        axios.get('https://receiver-backup.dagoras.io?data='+JSON.stringify(data))
            .then(response => {})
    }

    function renderButton(){
        return (
            isSubmit ? <span className="loader"></span>: "GỬI YÊU CẦU VAY"
        );
    }

    return (
        <div className="App">
            <div className="content">
                <div className={"header"}>
                    <img src={logo}/>
                    <h2 className={"fw-500"}>Để lại thông tin cho chúng tôi bạn sẽ được tư vấn gói vay phù hợp.</h2>
                </div>
                <FormControl error={Object.keys(fieldError).length > 0}>
                    <TextField id="fullname" name="fullname" label="Họ và Tên" variant="filled"
                               className="w100 mt-10 customInput"
                               onChange={(event) => data.fullName = event.target.value}
                               onBlur={validateOnBlurFullName}
                               required={true}
                               error={fieldError.fullName}
                               helperText={errorMessage.fullName}/>
                    <TextField id="phone" label="Số điện thoại" variant="filled"
                               className="w100 mt-10 customInput"
                               onBlur={validateOnBlurPhone}
                               required={true}
                               onChange={(event) => data.phone = event.target.value}
                               error={fieldError.phone}
                               helperText={errorMessage.phone}
                               />
                    <TextField id="identifier" label="Số căn cước công dân" variant="filled"
                               className="w100 mt-10 customInput"
                               onBlur={validateOnBlurIdentifier}
                               required={true}
                               onChange={(event) => data.identifier = event.target.value}
                               error={fieldError.identifier}
                               helperText={errorMessage.identifier}
                               />
                    <div className="mt-10">
                        <label className={"float-left required"}>Số tiền vay</label>
                        <label className={"float-right"}>{new Intl.NumberFormat().format(valueAmount) + ` VNĐ`}</label>
                    </div>
                    <Slider defaultValue={50000000} aria-label="Default" valueLabelDisplay="auto"
                            value={valueAmount}
                            onChange={(event: Event, newValue: number | number[]) => setValueAmount(newValue)}
                            getAriaValueText={getAmountText}
                            valueLabelFormat={getAmountText}
                            className={"sliderCustom"}
                            aria-labelledby="non-linear-slider"
                            step={1000000}
                            min={minAmount} max={maxAmount}/>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography
                            variant="body2"
                            onClick={() => setValueAmount(minAmount)}
                            sx={{cursor: 'pointer'}}
                        >
                            {new Intl.NumberFormat().format(minAmount) + ` VNĐ`}
                        </Typography>
                        <Typography
                            variant="body2"
                            onClick={() => setValueAmount(maxAmount)}
                            sx={{cursor: 'pointer'}}
                        >
                            {new Intl.NumberFormat().format(maxAmount) + ` VNĐ`}
                        </Typography>
                    </Box>
                            <div className={"w100 mt-20"}>
                                <Checkbox  className={"float-left"} onChange={(event)=>setConsent(event.target.checked)}/>
                                <div className={"w90 text-al-left float-left"}>
                                    <label className={" confirm_label"}>Bằng việc nhấn vào nút Gửi thông tin, tôi cam
                                        kết rằng, tôi đã đọc, hiểu và đồng ý với nội dung của
                                        <a target={"_blank"} href={"https://www.tnex.com.vn/dieu-khoan-dieu-kien/"}>
                                            Điều khoản, điều kiện về bảo vệ dữ liệu cá nhân</a> của TNEX đính kèm.</label>
                                </div>
                            </div>
                   <label className={"required-text mt-20 text-al-left"}>{validateMessage}</label>
                            <Button disabled={isSubmit} className={"mt-20 h-40"} variant="contained" onClick={submitData}>{renderButton()}</Button>
                </FormControl>
            </div>
        </div>
);
}

export default Base;

import logo from './logo.png';
import './Increase.css';
import {Autocomplete, Box, Button, Checkbox, FormControl, Slider, Stack, TextField, Typography} from "@mui/material";
import React, {useState, useEffect} from 'react';
import axios from "axios";
import "@fontsource/quicksand"; // Defaults to weight 400
import "@fontsource/quicksand/400.css"; // Specify weight
function Increase() {
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const minAmount=3000000;
  const maxAmount=100000000;
  const [data] = useState({
    fullName: "",
    phone: "",
    identifier: "",
    p_code: "",
    p_name: "",
    w_code: "",
    w_name: "",
    d_code: "",
    d_name: "",
    address: ""
  });
  // eslint-disable-next-line no-undef
  const [valueAmount, setValueAmount] = React.useState(50000000);
  const [consent, setConsent] = React.useState(false);
  const [districtSelect, setDistrictSelect] = React.useState(null);
  const [wardSelect, setWardSelect] = React.useState(null);


  function getAmountText(value: number){
    return   new Intl.NumberFormat().format(value) + ` VNĐ`
  }

  useEffect(() => {
    axios.get('https://location.dangky.info/tnex/province.json')
        .then(response => {
          setProvinces(response.data);
        })
        .catch(error => {
          console.error(error);
        });
  }, []);

  function onSelectProvince(value) {
    setDistricts([]);
    setDistrictSelect(null)
    setWards([])
    setWardSelect(null)
    data.d_code = "";
    data.d_name = "";
    data.w_code = "";
    data.w_name = "";
    if (value != null) {
      data.p_code = value.Id;
      data.p_name = value.Name;
      axios.get('https://location.dangky.info/tnex/p_' + value.Id + '.json')
          .then(response => {
            setDistricts(response.data);
          })
          .catch(error => {
            console.error(error);
          });
    } else {
      data.p_code = "";
      data.p_name = "";
    }

  }

  function onSelectDistrict(value) {
    setDistrictSelect(value)
    setWards([])
    setWardSelect(null)
    data.w_code = "";
    data.w_name = "";
    if (value != null) {
      data.d_code = value.Id;
      data.d_name = value.Name;
      axios.get('https://location.dangky.info/tnex/d_' + value.Id + '.json')
          .then(response => {
            setWards(response.data);
          })
          .catch(error => {
            console.error(error);
          });
    } else {
      data.d_code = "";
      data.d_name = "";
    }
  }

  function isVietnamesePhoneNumberValid(number) {
    return /(((\+|)84)|0)+([0-9]{9})\b/.test(number);
  }

  const [errorMessage, setErrorMessage] = React.useState({});
  const [fieldError, setFieldError] = React.useState({});
  function validateOnBlur() {
    setFieldError({})
    setErrorMessage({});
    setValidateMessage("");
    if (data.fullName.length < 2) {
      setFieldError({fullName:true});
      setErrorMessage( {fullName:"Sai định dạng họ tên"});
      return false;
    }
    if (!isVietnamesePhoneNumberValid(data.phone)) {
      setFieldError({phone:true})
      setErrorMessage( {phone:"Sai định dạng Số điện thoại"})
      return false;
    }
    if (data.identifier.length < 9) {
      setFieldError({identifier:true})
      setErrorMessage( {identifier:"Sai định dạng căn cước công dân"})
      return;
    }
    if (data.p_code.length===0) {
      setFieldError({p_code:true})
      setErrorMessage( {p_code:"Chưa nhập đầy đủ địa chỉ"})
      return false;
    }
    if (data.d_code.length ===0) {
      setFieldError({d_code:true})
      setErrorMessage( {d_code:"Chưa nhập đầy đủ địa chỉ"})
      return false;
    }
    if (data.w_code.length ===0) {
      setFieldError({w_code:true})
      setErrorMessage( {w_code:"Chưa nhập đầy đủ địa chỉ"})
      return false;
    }
    if (data.address.length ===0) {
      setFieldError({address:true})
      setErrorMessage( {address:"Chưa nhập đầy đủ địa chỉ"})
      return false;
    }
    return true

  }

  const [validateMessage, setValidateMessage] = React.useState("");
  function validateOnSubmit() {
    setValidateMessage("");
    if (!consent) {
      setValidateMessage("Yêu cầu chưa được gửi đi do \"Chưa tích vào Ô đồng ý Điều khoản, điều kiện về bảo vệ dữ liệu cá nhân\"");
      return false;
    }
    return true;
  }

  function submitData() {
    if(validateOnBlur()){
      if(validateOnSubmit()){
        setIsSubmit(true);
        callReceiver();
      }
    }
  }


  function callReceiver(){
    const config = {
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJwYXNzd29yZFwiOlwiXCIsXCJ1c2VybmFtZVwiOlwidG5leF9pbmNyZWFzZV9sYW5kaW5nX3BhZ2VcIixcImF1dGhvcml0aWVzXCI6W10sXCJhY2NvdW50Tm9uRXhwaXJlZFwiOnRydWUsXCJhY2NvdW50Tm9uTG9ja2VkXCI6dHJ1ZSxcImNyZWRlbnRpYWxzTm9uRXhwaXJlZFwiOnRydWUsXCJlbmFibGVkXCI6dHJ1ZX0iLCJleHAiOjE3OTI5MjU2NzMsImlhdCI6MTcwNjUyNTY3M30.l8SBDMd_1G5EJFCAPUPuSoFF1sZjSLO67KkmfY_UZSD3WBi_ylps4NjHz7XMhpNQTdnh7ksbcwFaYvkFc1zUzw',
        'Content-Type': 'application/json',
      },
    };


    axios.post( 'https://receiver.dagoras.io/receiver', {
      "fullName":data.fullName,
      "phone":data.phone,
      "identityNumber":data.identifier,
      "loanAmount":valueAmount,
      "provinceCode":data.p_code,
      "provinceName":data.p_name,
      "districtCode":data.d_code,
      "districtName":data.d_name,
      "wardCode":data.w_code,
      "wardName":data.w_name,
      "address":data.address,
      "link": window.location.href,
    }, config)
        .then(response => {
          window.location.href = "https://dagoras.tnexfinance.com.vn/thankyou";
        })
        .catch(function () {
          saveToBackup();
        });
  }
  const [isSubmit, setIsSubmit] = React.useState(false);
  function renderButton(){
    return (
        isSubmit ? <span className="loader"></span>: "GỬI YÊU CẦU VAY"
    );
  }
  function saveToBackup(){
    axios.get('https://receiver-backup.dagoras.io?data='+JSON.stringify(data))
        .then(response => {})
  }

  return (
      <div className="App-Increase">
        <div className="content-increase">
          <div className={"header"}>
            <img src={logo}/>
            <h2 className={"fw-500"}>Để lại thông tin cho chúng tôi bạn sẽ được tư vấn gói vay phù hợp.</h2>
          </div>
          <FormControl error={Object.keys(fieldError).length > 0}>
            <TextField id="fullname" name="fullname" label="Họ và Tên" variant="filled"
                       className="w100 mt-10 customInput"
                       onChange={(event) => data.fullName = event.target.value}
                       onBlur={validateOnBlur}
                       required={true}
                       error={fieldError.fullName}
                       helperText={errorMessage.fullName}/>
            <TextField id="phone" label="Số điện thoại" variant="filled"
                       className="w100 mt-10 customInput"
                       onBlur={validateOnBlur}
                       required={true}
                       onChange={(event) => data.phone = event.target.value}
                       error={fieldError.phone}
                       helperText={errorMessage.phone}
            />
            <TextField id="identifier" label="Số căn cước công dân" variant="filled"
                       className="w100 mt-10 customInput"
                       onBlur={validateOnBlur}
                       required={true}
                       onChange={(event) => data.identifier = event.target.value}
                       error={fieldError.identifier}
                       helperText={errorMessage.identifier}
            />
            <div className="mt-10">
              <label className={"float-left required"}>Số tiền vay</label>
              <label className={"float-right"}>{new Intl.NumberFormat().format(valueAmount) + ` VNĐ`}</label>
            </div>
            <Slider defaultValue={50000000} aria-label="Default" valueLabelDisplay="auto"
                    value={valueAmount}
                    onChange={(event: Event, newValue: number | number[]) => {
                      setValueAmount(newValue);
                    }}
                    getAriaValueText={getAmountText}
                    valueLabelFormat={getAmountText}
                    className={"sliderCustom"}
                    aria-labelledby="non-linear-slider"
                    step={1000000}
                    min={minAmount} max={maxAmount}/>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography
                  variant="body2"
                  onClick={() => setValueAmount(minAmount)}
                  sx={{cursor: 'pointer'}}
              >
                {new Intl.NumberFormat().format(minAmount) + ` VNĐ`}
              </Typography>
              <Typography
                  variant="body2"
                  onClick={() => setValueAmount(maxAmount)}
                  sx={{cursor: 'pointer'}}
              >
                {new Intl.NumberFormat().format(maxAmount) + ` VNĐ`}
              </Typography>
            </Box>
            <div className={"float-left"}>
              <div className={"lr-50 md-100 float-left"}>
                <Autocomplete
                    disablePortal
                    id="province"
                    className="mt-10 customInput float-left"
                    required={true}
                    options={provinces}
                    getOptionKey={option => option.Id}
                    getOptionLabel={option => option.Name}
                    onChange={
                      (event, value) =>
                          onSelectProvince(value)
                    }
                    sx={{width: 300}}
                    renderInput={
                      (params) =>
                          <TextField {...params} onBlur={validateOnBlur}
                                     error={fieldError.p_code}
                                     helperText={errorMessage.p_code} variant="filled" required={true} label="Tỉnh/Thành Phố"/>
                    }
                />
              </div>
              <div className={"rr-50 md-100 float-left"}>
                <Autocomplete
                    disablePortal
                    id="district"
                    className="mt-10 customInput"
                    options={districts}
                    getOptionKey={option => option.Id}
                    getOptionLabel={option => option.Name}
                    onChange={(event, value) => onSelectDistrict(value)}
                    sx={{width: 300}}
                    value={districtSelect}
                    renderInput={(params) => <TextField {...params} required={true} variant="filled"
                                                        label="Quận/Huyên"/>}
                />
              </div>
            </div>
            <div className={"float-left"}>
              <div className={"lr-50 md-100 float-left"}>
                <Autocomplete
                    disablePortal
                    id="ward"
                    className="mt-10 customInput"
                    options={wards}
                    getOptionKey={option => option.Id}
                    getOptionLabel={option => option.Name}
                    onChange={(event, value) => {
                      if (value != null) {
                        data.w_code = value.Id;
                        data.w_name = value.Name;
                      } else {
                        data.w_code = "";
                        data.w_name = "";
                      }
                      setWardSelect(value)
                    }}
                    sx={{width: 300}}
                    value={wardSelect}
                    renderInput={(params) => <TextField {...params} required={true} variant="filled"
                                                        label="Phường/Xã"/>}
                />
              </div>
              <div className={"rr-50 md-100 float-left"}>
                <TextField className="w100 mt-10 customInput"
                           onChange={(event) => data.address = event.target.value}
                           required={true}
                           id="address" label="Tên đường, số nhà" variant="filled"/>
              </div>
            </div>
            <div className={"w100 mt-20"}>
              <Checkbox  className={"float-left"} onChange={(event)=>setConsent(event.target.checked)}/>
              <div className={"w90 text-al-left float-left"}>
                <label className={" confirm_label"}>Bằng việc nhấn vào nút Gửi thông tin, tôi cam
                  kết rằng, tôi đã đọc, hiểu và đồng ý với nội dung của
                  <a target={"_blank"} href={"https://www.tnex.com.vn/dieu-khoan-dieu-kien/"}>
                    Điều khoản, điều kiện về bảo vệ dữ liệu cá nhân</a> của TNEX đính kèm.</label>
              </div>
            </div>
            <label className={"required-text mt-20 text-al-left"}>{validateMessage}</label>
            <Button disabled={isSubmit} className={"mt-20 h-40"} variant="contained" onClick={submitData}>{renderButton()}</Button>
          </FormControl>
        </div>
      </div>
  );
}

export default Increase;
